import styled from "styled-components";

const NewsDetailStyle = styled.div`
  .loading {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .news_detail_container {
    max-width: 800px;
    margin: 80px auto;
    padding: 20px 20px 70px 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;
  }

  .image_grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 10px;
    margin-bottom: 16px;
  }

  .grid_image {
    width: 100%;
    border-radius: 8px;
    object-fit: cover;
    transition: transform 0.3s;
  }

  .back__container {
    width: 100%;
    background: #8fd2ff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .back_icon {
    font-size: 1.8rem;
    color: #5161ce;
    cursor: pointer;
    padding-left: 0.2rem;
  }

  .news_detail_container {
    max-width: 600px;
    margin: 80px auto;
    padding: 20px 20px 70px 20px;
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    position: relative;

    img {
      width: 100%;
      height: 300px;
      border-radius: 8px;
      margin-bottom: 16px;
    }

    .head_news_detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h1 {
      font-size: 24px;
      margin-bottom: 8px;
    }

    .date {
      font-weight: bold;
      color: #777;
      font-size: 14px;
      margin-bottom: 16px;
    }

    .description {
      font-size: 16px;
      line-height: 1.6;
    }

    .delete_news_btn {
      width: 80px;
      border: none;
      font-size: 0.95rem;
      background: #b0252e;
      color: white;
      border-radius: 0.5rem;
      padding: 0.3rem 0.2rem;
      position: absolute;
      right: 20px;
      bottom: 20px;
    }
  }
`;

export default NewsDetailStyle;
