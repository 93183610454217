import React, { useEffect, useState } from "react";
import FloatNewsStyle from "./FloatNewsCardStyle";
import { TailSpin } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { axiosInstance } from "../../utils/axiosInstance";

const FloatNewsCard = () => {
  const [floatNews, setFloatNews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchFloatNews = async () => {
      try {
        const response = await axiosInstance.get("/admin/get-news");

        if (response.status === 200) {
          const responseData = response.data;

          const filteredFloatNews = responseData.filter((news) => {
            const createdAt = new Date(news.created_at);
            const oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

            return createdAt > oneWeekAgo;
          });

          setFloatNews(filteredFloatNews);
        } else {
          console.error("Fetch FloatNews Status Failed:", response.statusText);
        }
      } catch (error) {
        console.log("Fetch FloatNews Status Error: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFloatNews();
  }, []);

  const handleNewsDetail = async (newData) => {
    try {
      const response = await axiosInstance.get(`/news/${newData._id}`);
      if (response.status === 200) {
        const responseData = response.data;
        console.log("Response News Detail: ", responseData);
        navigate(`/news/${newData._id}`, { state: { newsId: newData._id } });
      } else {
        console.error("Fetch News Detail Status Failed:", response.statusText);
      }
    } catch (error) {
      console.log("Fetch News Status Error: ", error);
    }
  };

  if (isLoading) {
    return (
      <FloatNewsStyle>
        <TailSpin color="skyblue" />
      </FloatNewsStyle>
    );
  }
  if (floatNews.length === 0) {
    return (
      <FloatNewsStyle>
        <span className="no_news">No Recent News</span>
      </FloatNewsStyle>
    );
  }
  if (floatNews.length === 0) {
    return (
      <FloatNewsStyle>
        No Recent News
      </FloatNewsStyle>
    );
  }

  return (
    <Swiper
      slidesPerView={1}
      loop={true}
      breakpoints={{
        680: {
          slidesPerView: 2,
        },
        1050: {
          slidesPerView: 3,
        },
      }}
      pagination={{
        clickable: true,
      }}
      autoplay={{
        delay: 1000,
        disableOnInteraction: false,
      }}
      modules={[Navigation, Autoplay, Pagination]}
      className="mySwiper"
    >
      {floatNews.map((floatNewsData, index) => (
        <SwiperSlide key={index}>
          <FloatNewsStyle>
            <div
              onClick={() => handleNewsDetail(floatNewsData)}
              className="float-news-card"
            >
              {/* Display only the first image */}
              <img
                className="news_img"
                src={floatNewsData.recordImg[0]}
                alt={floatNewsData.title}
              />
              <div className="float_news_info">
                <div className="first">
                  <h1>
                    {floatNewsData.ceremony.slice(0, 15)}
                    {floatNewsData.ceremony.length > 15 ? "..." : ""}
                  </h1>
                  <p>{new Date(floatNewsData.date).toLocaleDateString()}</p>
                </div>
                <div className="second">
                  <p>
                    {floatNewsData.description.slice(0, 30)}
                    {floatNewsData.description.length > 30 ? "..." : ""}
                  </p>
                </div>
              </div>
            </div>
          </FloatNewsStyle>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default FloatNewsCard;
