import React, { useEffect, useState } from "react";
import AdminNewsFormStyle from "./AdminNewsFormStyle";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";
import Datetime from "react-datetime";
import CustomToast from "../toast";
import { toast } from "react-toastify";
import "react-datetime/css/react-datetime.css";
import { axiosInstance } from "../../utils/axiosInstance";

const AdminNewsForm = () => {
  const navigate = useNavigate();
  const [submitting, setSubmitting] = useState(false);
  const { isLoggedIn, userId } = useAuthentication();
  const [newsDate, setnewsDate] = useState({
    ceremony: "",
    date: "",
    description: "",
    record_img: [],
  });
  const [previewImages, setPreviewImages] = useState([]);

  const token = localStorage.getItem("token");

  useEffect(() => {
    if(!token){
      navigate("/")
    }
  }, [token])

  const handleReset = () => {
    setnewsDate({
      ceremony: "",
      date: "",
      description: "",
      record_img: [],
    });
    setPreviewImages([]);
  };

  const handlePost = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const uploadedImages = await Promise.all(
        newsDate.record_img.map((file) => uploadToCloudinary(file))
      );

      const imageUrls = uploadedImages.map((response) => response.secure_url);

      const response = await axiosInstance.post("/admin/post-news", {
        ...newsDate,
        record_img: imageUrls,
        userId,
      });

      if (response.status === 200) {
        toast.success("News Posted Successfully");
        navigate("/news");
      } else {
        throw new Error("Post failed");
      }
    } catch (error) {
      toast.error("Post News Failed! Try Again!");
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 2) {
      toast.error("You can only upload up to 2 images.");
      return;
    }

    setPreviewImages(files.map((file) => URL.createObjectURL(file)));

    setnewsDate((prevNewsData) => ({
      ...prevNewsData,
      record_img: files,
    }));
  };

  const uploadToCloudinary = (file) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", "allgtisocial");

    return fetch("https://api.cloudinary.com/v1_1/dcjktgmtr/image/upload", {
      method: "POST",
      body: formData,
    }).then((res) => res.json());
  };

  useEffect(() => {
    console.log(newsDate);
  }, [newsDate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setnewsDate((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  return (
    <AdminNewsFormStyle>
      <CustomToast />
      <form className="user_form" onSubmit={handlePost}>
        <div className="form__control">
          <label htmlFor="ceremony">Name Of Ceremony</label>
          <input
            type="text"
            name="ceremony"
            id="ceremony"
            placeholder="Ceremony"
            value={newsDate.ceremony}
            onChange={handleChange}
          />
        </div>
        <div className="form__control">
          <label htmlFor="date">Date</label>
          <Datetime
            inputProps={{
              placeholder: "Select Date",
              style: { width: "100%" },
            }}
            value={newsDate.date}
            onChange={(date) =>
              setnewsDate((prevUserData) => ({ ...prevUserData, date }))
            }
            dateFormat="YYYY-MM-DD"
            timeFormat={false}
          />
        </div>
        <div className="form__control">
          <label htmlFor="description">Description</label>
          <input
            type="text"
            name="description"
            placeholder="Description"
            id="description"
            value={newsDate.description}
            onChange={handleChange}
          />
        </div>
        <div className="form__control">
          <label htmlFor="record_img">Record Image</label>
          <input
            type="file"
            name="record_img"
            id="record_img"
            accept=".jpeg, .png, .jpg"
            onChange={handleFileUpload}
            multiple
          />
        </div>
        <div className="preview-images">
          {previewImages.map((src, index) => (
            <img key={index} src={src} alt={`Preview ${index}`} width="100" />
          ))}
        </div>
        <div className="new_form__btns">
          <button className="btn" type="reset" onClick={handleReset}>
            Reset
          </button>
          <button
            className="btn"
            type="submit"
            disabled={submitting}
          >
            {submitting ? "Posting..." : "Post"}
          </button>
        </div>
      </form>
    </AdminNewsFormStyle>
  );
};

export default AdminNewsForm;
