import React, { useEffect, useState } from "react";
import NewsDetailStyle from "./NewsDetailStyle";
import { GoArrowLeft } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";
import { TailSpin } from "react-loader-spinner";
import { axiosInstance } from "../../utils/axiosInstance";
import { motion } from "framer-motion";

const NewsDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isLoggedIn, userId } = useAuthentication();
  const [newsData, setNewsData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getNews = async () => {
      const newsId = location.state.newsId;
      try {
        const response = await axiosInstance(`/news/${newsId}`);
        if (response.status === 200) {
          const responseData = await response.data;
          setNewsData(responseData);
        } else {
          console.error(
            "Fetch News Detail Page Status Failed:",
            response.statusText
          );
        }
      } catch (error) {
        console.log("Fetch News Detail Status Error: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    getNews();
  }, [location.state.newsId]);

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteNews = async () => {
    try {
      const response = await axiosInstance.post(
        `/admin/delete-news/${newsData._id}`
      );
      if (response.status === 200) {
        const responseData = await response.data;
        console.log("Response Delete News: ", responseData);
        navigate("/news");
      } else {
        console.error("Delete News Status Failed:", response.statusText);
      }
    } catch (error) {
      console.log("Delete News Status Error: ", error);
    }
  };

  return (
    <NewsDetailStyle>
      <div className="back__container">
        <span className="back_icon" onClick={handleBack}>
          <GoArrowLeft color="#5161ce" />
        </span>
      </div>
      {isLoading ? (
        <div className="loading">
          <TailSpin color="skyblue" radius={8} />
        </div>
      ) : (
        newsData && (
          <motion.div
            className="news_detail_container"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8 }}
          >
            <div className="image_grid">
              {newsData.recordImg.map((imgSrc, index) => (
                <motion.img
                  key={index}
                  src={imgSrc}
                  alt={`${newsData.ceremony} - ${index + 1}`}
                  whileHover={{ scale: 1.05 }}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                  className="grid_image"
                />
              ))}
            </div>
              <div className="head_news_detail">
              <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.2 }}
              >
                {newsData.ceremony}
              </motion.h1>
              <motion.p
                className="date"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.4 }}
              >
                {new Date(newsData.date).toLocaleDateString()}
              </motion.p>
              </div>
            <motion.p
              className="description"
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              {newsData.description}
            </motion.p>
            {isLoggedIn && userId && (
              <motion.button
                className="delete_news_btn"
                onClick={handleDeleteNews}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.8 }}
              >
                Delete
              </motion.button>
            )}
          </motion.div>
        )
      )}
    </NewsDetailStyle>
  );
};

export default NewsDetail;
