import React, { useEffect, useRef, useState, useMemo } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Logo from "../../assets/imgs/logo.jpg";
import HomeStyle from "./HomeStyle";
import AdminLoginForm from "../../components/AdminLoginForm/AdminLoginForm";
import { FaUser } from "react-icons/fa";
import { FiLogOut } from "react-icons/fi";
import useAuthentication from "../../hooks/useAuthentication";
import { TailSpin } from "react-loader-spinner";
import { Link, useNavigate } from "react-router-dom";
import FloatNewsCard from "../../components/floatNewsCard/FloatNewsCard";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { axiosInstance } from "../../utils/axiosInstance";
import { motion } from "framer-motion";

const Home = () => {
  const navigate = useNavigate();
  const [openLoginForm, setOpenLoginForm] = useState(false);
  const { isLoggedIn, userId } = useAuthentication();
  const [members, setMembers] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [hovered, setHovered] = useState(false);
  const TableRef = useRef(null);
  const [isLoadingTable, setIsLoadingTable] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 7;

  useEffect(() => {
    const getUsers = async () => {
      try {
        const response = await axiosInstance.get("/admin/get-members");
        if (response.status === 200) {
          setMembers(response.data);
        } else {
          console.error("Fetch Members Status Failed:", response.statusText);
        }
      } catch (error) {
        console.error("Fetch Members Status Error:", error);
      } finally {
        setIsLoadingTable(false);
      }
    };

    getUsers();
  }, []);

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);

  const handleMouseDown = (e) => {
    if (!hovered || !TableRef.current) return;

    const startX = e.clientX;
    const scrollLeft = TableRef.current.scrollLeft || 0;

    const handleMouseMove = (e) => {
      const x = e.clientX - startX;
      if (TableRef.current) {
        TableRef.current.scrollLeft = scrollLeft - x;
      }
    };

    const handleMouseUp = () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };

    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleSearchInputChange = (e) => setSearchQuery(e.target.value);

  const handleOpenLoginForm = () => {
    setOpenLoginForm(true);
    console.log(openLoginForm);
  };

  const handleLogout = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post("/logout", { userId });
      if (response.status === 200) {
        navigate("/about");
        localStorage.setItem("isLoggedIn", false);
        localStorage.removeItem("token");
      } else {
        console.error("Logout Failed:", response.statusText);
      }
    } catch (error) {
      console.error("Logout Error:", error);
    }
  };

  const handleUser = async (member) => {
    try {
      const response = await axiosInstance.get(`/user/${member._id}`);
      if (response.status === 200) {
        navigate(`/users/${member._id}`, { state: { userId: member._id } });
      } else {
        console.error("Fetch Member Detail Status Failed:", response.statusText);
      }
    } catch (error) {
      console.error("Fetch Member Detail Error: ", error);
    }
  };

  const memoizedFilteredMembers = useMemo(() => {
    const query = searchQuery.toLowerCase();
    return members.filter((member) => {
      if (member) {
        return (
          member.name.toLowerCase().includes(query) ||
          member.position.toLowerCase().includes(query) ||
          member.region.toLowerCase().includes(query) ||
          member.year.toString().includes(query) ||
          member.major.toLowerCase().includes(query)
        );
      }
      return false;
    });
  }, [searchQuery, members]);

  const handlePaginationChange = (event, value) => setCurrentPage(value);

  const currentItems = memoizedFilteredMembers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <HomeStyle>
      <Navbar />
      <FloatNewsCard />
      <motion.div className="head" initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
        <img src={Logo} alt="Logo" className="logo" width="150px" height="150px" />
        <span>Mandalay GTI ကျောင်းပေါင်းစုံ လူမှုရေးအသင်း</span>
      </motion.div>

      <motion.div className="search_box" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 0.2 }}>
        <input type="text" placeholder="Search...." className="search_input" value={searchQuery} onChange={handleSearchInputChange} />
      </motion.div>

      <motion.div
        className="table--container"
        ref={TableRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseDown={handleMouseDown}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        {isLoadingTable ? (
          <div className="loading">
            <TailSpin color="skyblue" />
          </div>
        ) : (
          <table className="table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Position</th>
                <th>Region</th>
                <th>Year</th>
                <th>Major</th>
              </tr>
            </thead>
            <tbody className="member_datas">
              {currentItems.map((member) => (
                <tr key={member._id} onClick={() => handleUser(member)}>
                  <td>{member.name}</td>
                  <td>{member.position}</td>
                  <td>{member.region}</td>
                  <td>{member.year}</td>
                  <td>{member.major}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </motion.div>

      <motion.div className="pagination" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5, delay: 0.6 }}>
        <Stack spacing={2}>
          <Pagination
            count={Math.ceil(memoizedFilteredMembers.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePaginationChange}
            size="medium"
            color="primary"
          />
        </Stack>
      </motion.div>

      {!isLoggedIn ? (
        <motion.button className="login_button" onClick={handleOpenLoginForm} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.3 }}>
          <FaUser />
        </motion.button>
      ) : (
        <motion.button className="logout_button" onClick={handleLogout} initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.3 }}>
          <FiLogOut />
        </motion.button>
      )}

      {openLoginForm && !isLoggedIn && <AdminLoginForm setOpenLoginForm={setOpenLoginForm} />}
    </HomeStyle>
  );
};

export default Home;
