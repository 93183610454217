import React, { useState, useEffect, useRef } from "react";
import "./NavbarStyle.css";
import Logo from "../../assets/imgs/logo.jpg";
import { Link, useLocation } from "react-router-dom";
import { FaEdit, FaHome, FaNewspaper, FaUserFriends } from "react-icons/fa";

const Navbar = () => {
  const [activeItem, setActiveItem] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const horiSelectorRef = useRef(null);
  let isLoggedIn = localStorage.getItem('token');
  const handleItemClick = (index) => {
    setActiveItem(index);
  };
  useEffect(() => {
    const path = location.pathname;
    const targetIndex = [
      ...document.querySelectorAll("#navbarSupportedContent ul li a"),
    ].findIndex((a) => a.getAttribute("href") === path);
    setActiveItem(targetIndex);
  }, [location.pathname]);
  
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  useEffect(() => {
    const tabsNewAnim = document.getElementById("navbarSupportedContent");
    const activeItemNewAnim = tabsNewAnim?.querySelector(".active");

    if (activeItemNewAnim && horiSelectorRef.current) {
      const activeWidthNewAnimHeight = activeItemNewAnim.clientHeight;
      const activeWidthNewAnimWidth = activeItemNewAnim.clientWidth;
      const itemPosNewAnimTop = activeItemNewAnim.offsetTop;
      const itemPosNewAnimLeft = activeItemNewAnim.offsetLeft;

      const horiSelector = horiSelectorRef.current;

      horiSelector.style.top = `${itemPosNewAnimTop}px`;
      horiSelector.style.left = `${itemPosNewAnimLeft}px`;
      horiSelector.style.height = `${activeWidthNewAnimHeight}px`;
      horiSelector.style.width = `${activeWidthNewAnimWidth}px`;
    }
  }, [activeItem]);

  return (
    <>
      <nav className={`${windowWidth > 740 ? "navbar" : "mobile_nav"}`}>
        {windowWidth > 740 && (
          <a className="navbar-brand navbar-logo" href="/">
            <img src={Logo} alt="logo" width="50px" height="50px" />
            <span>All GTI Social Association Mandalay Region</span>
          </a>
        )}
        <div id="navbarSupportedContent">
          <ul className="navbar-nav ml-auto">
            <div className="hori-selector" ref={horiSelectorRef}>
              <div className="left"></div>
              <div className="right"></div>
            </div>
            <li
              className={`nav-item ${activeItem === 0 ? "active" : ""}`}
              onClick={() => handleItemClick(0)}
            >
              <Link className="nav-link" to="/">
                {windowWidth > 740 ? (
                  <p>Home</p>
                ) : (
                  <FaHome
                    color={
                      activeItem === 0 ? "var(--primary-blue-color)" : "white"
                    }
                  />
                )}
              </Link>
            </li>
            <li
              className={`nav-item ${activeItem === 1 ? "active" : ""}`}
              onClick={() => handleItemClick(1)}
            >
              <Link className="nav-link" to="/news">
                {windowWidth > 740 ? (
                  <p>News</p>
                ) : (
                  <FaNewspaper
                    color={
                      activeItem === 1 ? "var(--primary-blue-color)" : "white"
                    }
                  />
                )}
              </Link>
            </li>
            <li
              className={`nav-item ${activeItem === 2 ? "active" : ""}`}
              onClick={() => handleItemClick(2)}
            >
              <Link className="nav-link" to="/about">
                {windowWidth > 740 ? (
                  <p>About</p>
                ) : (
                  <FaUserFriends
                    color={
                      activeItem === 2 ? "var(--primary-blue-color)" : "white"
                    }
                  />
                )}
              </Link>
            </li>
            {/* {testingLocalStorage !== null && testingLocalStorage === 'true' &&  !loading && ( */}
            {isLoggedIn && (
              <li
                className={`nav-item ${activeItem === 3 ? "active" : ""}`}
                onClick={() => handleItemClick(3)}
              >
                <Link className="nav-link" to="/admin/forms/user">
                  {windowWidth > 740 ? (
                    <p>Edit</p>
                  ) : (
                    <FaEdit
                      color={
                        activeItem === 3 ? "var(--primary-blue-color)" : "white"
                      }
                    />
                  )}
                </Link>
              </li>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
