import React from 'react';
import LoadingPageStyle from './LoadingStyle';

const Loading = () => {
  return (
    <LoadingPageStyle>
      <h2>Loading...</h2>
    </LoadingPageStyle>
  );
};

export default Loading;
