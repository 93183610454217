// SwitchButton.js

import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SwitchButtonStyle from './SwitchButtonStyle';

const SwitchButton = ({ initialValue = false, onChange }) => {
  const [isChecked, setIsChecked] = useState(initialValue);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const isNewsRoute = location.pathname === '/admin/forms/news';
    setIsChecked(isNewsRoute);
  }, [location]);

  const handleToggle = () => {
    const newValue = !isChecked;
    setIsChecked(newValue);
    if (onChange) {
      onChange(newValue);
    }

    const newRoute = newValue ? '/admin/forms/news' : '/admin/forms/user';
    navigate(newRoute);
  };

  return (
    <SwitchButtonStyle checked={isChecked} onClick={handleToggle}>
      <span className='switch_value'>User Form</span>
      <div className='container'>
        <div className='main_switch'>{isChecked ? 'News Form' : 'User Form'}</div>
      </div>
      <span className='switch_value'> News Form</span>
    </SwitchButtonStyle>
  );
};

export default SwitchButton;
