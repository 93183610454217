import React, { useEffect, useState } from "react";
import UserDetailStyle from "./UserDetailStyle";
import { useLocation, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { TailSpin } from "react-loader-spinner";
import useAuthentication from '../../hooks/useAuthentication'
import { axiosInstance } from "../../utils/axiosInstance";

const UserDetail = () => {
  const [userData, setUserData] = useState(null);
  const {isLoggedIn, userId} = useAuthentication()
  const location = useLocation();
  const navigate = useNavigate();

  const getUser = async () => {
    const userId = location.state.userId;
    try {
      const response = await axiosInstance.get(`/user/${userId}`);
      if (response.status === 200) {
        const responseData = response.data;
        setUserData(responseData);
        console.log("Response Member Detail Page: ", responseData);
      } else {
        console.error(
          "Fetch Member Detail Page Status Failed:",
          response.statusText
        );
      }
    } catch (error) {
      console.log("Fetch Member Detail Status Error: ", error);
    }
  };

  useEffect(() => {
    getUser();
  }, [location.state.userId]);

  const handleBack = () => {
    navigate(-1);
  };


  const handleEdit = ()=>{
    navigate('/admin/forms/user', { state: { edit: true, memberId: userData._id, memberData: userData } });
  }

  const handleDelete  = async ()=>{
    try {
      const response = await axiosInstance.post(`/admin/delete-member/${userData._id}`);
    if(response.status === 200){
      const responseData = response.data;
      console.log("Response Delete Memeber: " , responseData)
      navigate('/')
    }else {
      console.error(
        "Delete Member Status Failed:",
        response.statusText
      );
    }} catch (error) {
      console.log("Delete Member Status Error: ", error);

    }
  }

  return (
    <UserDetailStyle>
      <div className="back__container">
        <span className="back_icon" onClick={handleBack}>
          <GoArrowLeft color="#5161ce" />
        </span>
      </div>
      {userData ? (
        <div className="user__detail">
          <div className="user__detail_container">
            <div className="user_detail_head">
              <div className="name_section">
                <h1>
                  {userData.name} <span>({userData.position})</span>
                </h1>
              </div>
              <div className="year_section">
                <p className="label">From</p>
                <p className="year">
                  {userData.year}
                </p>
              </div>
            </div>

            <div className="user_detail_body">
              <div className="major_region_section">
                <p>
                  <span className="label">Major - </span> {userData.major}
                </p>
                <p>
                  <span className="label">Region - </span> {userData.region}
                </p>
              </div>
              {/* <div className="annotation_section">
                <p className="label">Annotation - </p>
                <p className="note">{userData.annotation}</p>
              </div> */}
              {isLoggedIn && userId && <div className="buttons">
                <button className="edit" onClick={handleEdit}>Edit</button>
                <button className="delete" onClick={handleDelete}>Delete</button>
              </div>}
            </div>
          </div>
        </div>
      ) : (
        <div className="loading">
          <TailSpin color="skyblue" radius={8} />
        </div>
        )}
    </UserDetailStyle>
  );
};

export default UserDetail;
