// Routers.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home/Home';
import News from './pages/News/News';
import About from './pages/About/About';
import Forms from './pages/Forms/Forms';
import UserDetail from './pages/UserDetail/UserDetail';
import AdminUserForm from './components/AdminUsersForm/AdminUserForm';
import AdminNewsForm from './components/AdminNewsForm/AdminNewsForm';
import NewsDetail from './pages/NewsDetail/NewsDetail';
import NotFound from './pages/404/NotFound';
import Loading from './pages/Loading/Loading';

export default function Routers() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulating a delay of 2 seconds for loading purposes
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  if (loading) {
    return <Loading/>;
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/news' element={<News />} exact />
        <Route path='/about' element={<About />} />
        <Route path='/admin/forms' element={<Forms />}>
          <Route index element={<AdminUserForm />} />
          <Route path='user' element={<AdminUserForm />} />
          <Route path='news' element={<AdminNewsForm />} />
        </Route>
        <Route path='/users/:userId' element={<UserDetail />} />
        <Route path='/news/:newsId' element={<NewsDetail />} />
        <Route path='*' element={<NotFound/>}/>
      </Routes>
    </BrowserRouter>
  );
}
