import React, { useState, useEffect } from "react";
import AdminUsersFormStyle from "./AdminUsersFormStyle";
import { useNavigate, useLocation } from "react-router-dom";
import useAuthentication from "../../hooks/useAuthentication";
import CustomToast from "../toast"; // Import the CustomToast component
import { toast } from "react-toastify"; // Import the toast method
import { axiosInstance } from "../../utils/axiosInstance";

const AdminUserForm = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [submitting, setSubmitting] = useState(false);
  const { isLoggedIn, userId } = useAuthentication();

  const token = localStorage.getItem("token");

  useEffect(() => {
    if(!token){
      navigate("/")
    }
  }, [token])

  const [usersData, setUsersData] = useState(() => {
    const state = location.state;
    if (state && state.edit !== undefined && state.memberId) {
      console.log("For Testing Member from state", state.memberData);
      return {
        name: state.memberData.name || "",
        position: state.memberData.position || "",
        region: state.memberData.university || "",
        year: state.memberData.year || "",
        major: state.memberData.major || "",
        // note: state.memberData.annotation || "",
        edit: state.edit,
        memberId: state.memberId,
      };
    } else {
      return {
        name: "",
        position: "",
        region: "",
        year: "",
        major: "",
        // note: "",
        edit: false,
        memberId: null,
      };
    }
  });

  useEffect(() => {
    const state = location.state;
    if (state && state.edit !== undefined && state.memberId) {
      setUsersData((prevUserData) => ({
        ...prevUserData,
        edit: state.edit,
        memberId: state.memberId,
      }));
    }
  }, [location]);

  const handleReset = () => {
    setUsersData({
      name: "",
      position: "",
      region: "",
      year: "",
      major: "",
      // note: "",
      edit: false,
      memberId: null,
    });
  };

  const handlePost = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    console.log("Form Data:", usersData);

    try {
      const response = await axiosInstance.post("/admin/post-member", {
        ...usersData,
        userId,
      });

      if (response.status === 200) {
        const responseData =  response.data;
        console.log("Response:", responseData);
        toast.success("Member data posted successfully!");
        navigate("/");
      } else {
        throw new Error("Post failed");
      }
    } catch (error) {
      toast.error("Failed to post member data. Please try again.");
      console.error("Post Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUsersData((prevUserData) => ({
      ...prevUserData,
      [name]: value,
    }));
  };

  return (
    <AdminUsersFormStyle>
      <CustomToast />
      <form className="user_form" onSubmit={handlePost}>
        <div className="form__control">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Name"
            value={usersData.name}
            onChange={handleChange}
          />
        </div>
        <div className="form__control">
          <label htmlFor="position">Position</label>
          <input
            type="text"
            name="position"
            placeholder="Position"
            id="position"
            value={usersData.position}
            onChange={handleChange}
          />
        </div>
        <div className="form__control">
          <label htmlFor="region">Region</label>
          <input
            type="text"
            name="region"
            placeholder="Region"
            id="region"
            value={usersData.region}
            onChange={handleChange}
          />
        </div>
        <div className="form__control">
          <label htmlFor="year">Year (19--)</label>
          <input
            type="text"
            name="year"
            placeholder="Year (19-- , 20--)"
            id="year"
            value={usersData.year}
            onChange={handleChange}
          />
        </div>
        <div className="form__control">
          <label htmlFor="major">Major</label>
          <input
            type="text"
            name="major"
            placeholder="Major"
            id="major"
            value={usersData.major}
            onChange={handleChange}
          />
        </div>
        {/* <div className="form__control">
          <label htmlFor="note">Annotation</label>
          <input
            type="text"
            name="note"
            placeholder="Annotation (မှတ်ချက်)"
            id="note"
            value={usersData.note}
            onChange={handleChange}
          />
        </div> */}
        <div className="login_form__btns">
          <button className="btn" type="reset" onClick={handleReset}>
            Reset
          </button>
          <button
            className="btn"
            onClick={handlePost}
            type="submit"
            disabled={submitting}
          >
            {submitting ? "Posting..." : usersData.edit ? "Update" : "Post"}
          </button>
        </div>
      </form>
    </AdminUsersFormStyle>
  );
};

export default AdminUserForm;
