import { styled } from "styled-components";

const FloatNewsStyle = styled.div`
  .loading{
    display: flex;
    justify-content: center;
    align-items: center;
  }
    .no_news{
      margin-top: 20px;
      color: orange;
      font-size: 15px;
      font-weight: bold; 
    }

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
  padding: 0 0.5rem;
  box-sizing: border-box;

  .float-news-card {
    width: 100%;
    height: 120px;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;
    
  }
  
  .news_img {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    object-fit: cover;
    margin-right: 20px;
  }
  
  .float_news_info {
    flex: 1;
  }

  .float_news_info .first{
    display: flex;
    justify-content: space-between;
  }
  
  .float_news_info h1 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .float_news_info p {
    font-size: 14px;
    color: #666;
  }

  @media (max-width: 740px){
    margin-top: 0.3rem;
    // padding: 0;
  }
  `;

export default FloatNewsStyle;
