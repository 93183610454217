import { useState, useEffect } from 'react';

const useAuthentication = () => {
  const [authData, setAuthData] = useState({
    isLoggedIn: null,
    userId: null,
    loading: true,
  });

  const token = localStorage.getItem('token');

  const fetchLoggedInStatus = async () => {
    try {
      const response = await fetch('https://mandalay-gtib.vercel.app/getLoggedInStatus', {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });

      if (response.ok) {
        const responseData = await response.json();
        setAuthData({
          isLoggedIn: responseData.isLoggedIn,
          userId: responseData.userId,
          loading: false,
        });
        localStorage.setItem('isLoggedIn', responseData.isLoggedIn);
        console.log("Fetch LoggedIn status Success : ", authData)
      } else {
        console.error('Fetch LoggedIn Status Failed:', response.statusText);
      }
    } catch (error) {
      console.error('Fetch LoggedIn Status Error:', error);
    }
  };
    
  useEffect(() => {
    fetchLoggedInStatus();    
  }, []);

  return authData;

};

export default useAuthentication;
