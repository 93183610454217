import {styled} from 'styled-components'

const AdminUsersFormStyle = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2rem;

    .user_form{
        width: 100%;
    }

    .user_form .form__control{
        padding: 0.5rem 1rem;
    }

    .form__control label{
        display: block;
        margin-bottom: 5px;
    }

    .form__control input{
        width: 100%;
        padding: 0.5rem 0.8rem;
    }

    .login_form__btns{
        text-align: end;
        margin-top: 1rem;
    }

    .login_form__btns .btn{
        margin-right: 1rem;
        padding: 0.5rem 1.5rem;
        border: none;
        cursor: pointer;
    }

    .login_form__btns .btn:hover{
        background: var(--primary-yellow-color);
        transition: all 0.5s;
    }
`

export default AdminUsersFormStyle