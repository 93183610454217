import { styled } from 'styled-components';

const NotFoundStyle = styled.div`
    height: 100vh;
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h2{
        color: #a3001e;
    }

    p{
        color: #696969;
        display: flex;
        align-items: center;
        margin-top: 2rem;
    }
    button{
        background: #212121;
        color: #fff;
        border: none;
        padding: 0.5rem 1rem;
        border-radius: 20px;
    }
`

export default NotFoundStyle