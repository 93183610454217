// forms/Forms.js

import React, { useState } from 'react';
import { useNavigate, Outlet } from 'react-router-dom';
import Navbar from '../../components/Navbar/Navbar';
import FormStyle from './FormStyle';
import SwitchButton from '../../components/SwitchButton/SwitchButton';
import AdminUserForm from '../../components/AdminUsersForm/AdminUserForm';
import AdminNewsForm from '../../components/AdminNewsForm/AdminNewsForm';

const Forms = () => {
  const [switchValue, setSwitchValue] = useState(false);
  const navigate = useNavigate();

  const handleSwitchChange = (newValue) => {
    setSwitchValue(newValue);
    const newRoute = newValue ? 'news' : 'user';
    navigate(newRoute);
  };

  return (
    <FormStyle>
      <Navbar />
      <div className='something'>
        <SwitchButton initialValue={switchValue} onChange={handleSwitchChange} />
        <div className='forms'>
          <Outlet />
        </div>
      </div>
    </FormStyle>
  );
};

export default Forms;
