import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import AboutStyle from "./AboutStyle";
import Logo from "../../assets/imgs/logo.jpg";
import Contact from '../../assets/imgs/Contact-us.png'
import { HiMiniArrowDownTray } from "react-icons/hi2";
import { CgMail } from "react-icons/cg";
import { MdOutlinePhoneInTalk } from "react-icons/md";
import { FaRegAddressCard } from "react-icons/fa6";

const About = () => {
  return (
    <AboutStyle>
      <Navbar />
      <div className="about_head">
        <div className="about_head_detail">
          <p>
            ဤအသင်းကို မန္တလေးဒေသကြီးအတွင်း အခြေပြုနေထိုင်လျက်ရှိသော G.T.I
            ကျောင်းပေါင်းစုံမှ ကျောင်းဆင်းလာသူများနှင့် ဖွဲ့စည်းပြီး
            အသင်း၏အမည်ကို “မန္တလေးဒေသ အခြေစိုက် G.T.I ကျောင်းပေါင်းစုံ လူမှုရေး
            အသင်း” (All G.T.I Social Association Mandalay Region) ဟု
            ခေါ်တွင်ပါသည်။
          </p>
        </div>
      </div>

      <div className="about_purpose">
        <p>အသင်း ဖွဲ့စည်းရခြင်း၏ ရည်ရွယ်ချက်များမှာ အောက်ပါအတိုင်း ဖြစ်သည် -</p>
        <div className="purpose_cards">
          <div className="purpose_card">
            <span>(က)</span> မန္တလေးဒေသအခြေပြု နေထိုင်လျက်ရှိကြသော G.T.I
            ကျောင်းဆင်းအချင်းချင်း တစ်ဦးနှင့်တစ်ဦး ခင်မင်ရင်းနှီး လေးစားမှုများ
            အဓွန့်ရှည်စေရန်။
          </div>
          <div className="purpose_card">
            <span>(ခ)</span> အသင်းသားများနှင့် မိသားစုတို့၏ အရေးကိစ္စရပ်များကို
            ဝိုင်းဝန်း ကူညီပံ့ပိုး ဆောင်ရွက်ရန်။
          </div>
          <div className="purpose_card">
            <span>(ဂ)</span> ဘာသာရေး၊ သာသနာရေး၊ လူမှုရေးနှင့် ယဉ်ကျေးမှုဆိုင်ရာ
            ကိစ္စရပ်များကို ပူးပေါင်းဆောင်ရွက်ရန်။
          </div>
          <div className="purpose_card">
            <span>(ဃ)</span> အသင်းအနေဖြင့် မည်သည့်ပါတီနိုင်ငံရေးကိုမှ
            ပါဝင်လှုပ်ရှား ဆောင်ရွက်ခြင်းမပြုဘဲ၊ လူမှုရေးလုပ်ငန်းများကိုသာ
            တစ်စိုက် မတ်မတ် ဦးတည်ကြိုးပမ်း ဆောင်ရွက်ရန်။
          </div>
        </div>
      </div>

      <div className="about_rules">
        <p>အသင်း၏ ဖွဲ့စည်းပုံစည်းမျဉ်းများ - </p>
        <span>
          အသင်းမှ ဖွဲ့စည်းပုံစည်းမျဉ်းများကို စနစ်တကျ ရေးဆွဲခဲ့ပြီး
          အခန်း(၁၄)ခန်းဖြင့် ပြဌာန်းထားပါသည်။ ထိုအခန်းများပါ အချက်များနှင့်
          စည်းကမ်းများကို စနစ်တကျလိုက်နာရန်မှာလဲ အရေးကြီးသည် (သို့မဟုတ်)
          အဖွဲ့အစည်းမှ ထုတ်ပယ်ခြင်းကို ခံရမည်ဖြစ်သည်။
          <b>
            ဖွဲ့စည်းပုံစည်းမျဉ်းများဖတ်ရှုရန် Download Button အားနှိပ်၍
            ဖတ်ရှုနိုင်သည်{" "}
            <a
              className="download_btn"
              href="https://drive.google.com/file/d/1ti8mKm4mFSFwfcP1ZwFLhI_HgFhgNg4D/view"
              target="_blank"
            >
             {<HiMiniArrowDownTray style={{fontWeight: 'bold', fontSize: "1.5rem"}}/>} Download To Our Rules
            </a>
          </b>
        </span>
      </div>

      <div className="about_contact">
        <div className="contact_info">
          <h1>Contact Us</h1>
          <div className="contact_data"><span><CgMail /></span> - allgtisocialassociationmdy @gmail.com</div>
          <div className="contact_data"><span><MdOutlinePhoneInTalk /></span> - +95 9 2005562, +95 9 426804522</div>
          <div className="contact_data"><span><FaRegAddressCard /></span> - 78 street, Between: 33 x 34 street, Yadanarpon super center basement, Mandalay Township.</div>
        </div>
        <div className="contact_img">
          <img src={Contact} alt="Contact"/>
        </div>
      </div>
    </AboutStyle>
  );
};

export default About;