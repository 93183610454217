import styled from 'styled-components';

const SwitchButtonStyle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;

  .switch_value {
    font-family: 'english-3', sans-serif;
    font-weight: bold;
    color: ${(props) => (props.checked ? 'var(--primary-blue-color)' : '#ccc')};
    margin: 0 0.5rem;
  }
  .switch_value:nth-of-type(1){
    color: ${(props) => (props.checked ? '#ccc' : 'var(--primary-blue-color)')};
  }
  
  .container {
    width: 80%;
    max-width: 200px;
    background-color: ${(props) => (props.checked ? '#ccc' : '#ccc')};
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: ${(props) => (props.checked ? 'flex-end' : 'flex-start')};
    padding: 5px;
    cursor: pointer;
    user-select: none;
    transition: opacity 0.3s ease; /* Optional: Add a transition for a smoother effect */
  }

  .main_switch {
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 15px;
    box-shadow: 1px 2px 10px #000;
    transform: translateX(${(props) => (props.checked ? '0' : '0')});
  }
`;

export default SwitchButtonStyle;
