import styled from "styled-components";


const BackDropStyle = styled.div`
    width: 100%;
    height: 100vh;
    background: var(--backdrop-color);
    padding: 0 0.5rem;
    position: fixed;
    top:0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
`

export default BackDropStyle