import React from "react";
import BackDropStyle from "./BackdropStyle.js";

const Backdrop = ({ children, onClose }) => {
  return (
    <BackDropStyle className="backdrop" onClick={onClose}>
      {children}
    </BackDropStyle>
  );
};

export default Backdrop;
