// AboutStyle.jsx
import { styled } from 'styled-components';
import backgroundImage from '../../assets/imgs/01.jpg'; // Import the image using require or specify the correct path

const AboutStyle = styled.div`
  margin-top: 4rem;

  .about_head {
    width: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
      url(${backgroundImage});
    background-size: cover;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
  }

  .about_head_detail{
    padding: 2rem 2rem;
    background: rgba(0,0,0,0.3);
    border: 2px dotted white;
    text-align: justify;
  }

  .about_head_detail h1{
    margin-bottom: 1rem;
  }
  .about_head_detail p{
    font-size: 1.5rem;
    line-height: 2;
    color: #d9d9d9;
  }

  .about_purpose {
    margin-top: 2rem;
    padding: 0 5rem;
  }

  .about_purpose p{
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "myanmar-2";
  }

  .purpose_cards{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  .purpose_cards .purpose_card{
    width: 20%;
    background: rgba(46, 126, 255, 0.3);
    text-align: center;
    font-size: 1.1rem;
    padding: 1rem;
    line-height: 2;
    border-radius: 20px;
    box-sizing:border-box;
    box-shadow: 1px 1px 3px gray;
  }

  .purpose_card span{
    display: block;
    text-align: center;
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  .about_rules{
    margin-top: 3rem;
    padding:1rem 5rem;
    background: #b1defc;
  }

  .about_rules p{
    font-size: 1.5rem;
    font-weight: bold;
    font-family: "myanmar-2";
  }

  .about_rules span{
    display: block;
    font-size: 1.1rem;
    margin-top: 2rem;
    line-height: 2;
  }

  .about_rules span b{
    display: flex;
    justify-content: space-between;
  }
  .about_rules .download_btn{
    text-decoration: none;
    background-color: rgb(78, 15, 129);
    color: #fff;
    border-radius: 10px;
    padding: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
  }

  .about_contact{
    width: 80%;
    margin: 4rem auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact_info{
    width: 100%;
  }
  .contact_img{
    width: 100%;
  }
  .contact_img img{
    width: 100%;
  }

  .about_contact .contact_info h1{
    font-size: 3rem;
    color: rgb(78, 15, 129);
    margin-bottom: 2rem;
  }

  .contact_data{
    width: 100%;
    background: #c0d0e2;
    display: flex;
    flex-direction: column
    justify-content: center;
    align-items: center;
    padding: 1rem;
    border-radius: 50px;
    margin-bottom: 1rem;
  }

  .contact_data span{
    font-size: 1.8rem;
    margin-right: 1rem;
  }

  @media (max-width: 1000px){
    margin: 0;

    .about_head_detail p{
      font-size: 1.2rem;
    }

    .about_purpose {
      padding: 0 1rem;
    }

    .purpose_cards{
      display: block;
    }

    .purpose_cards .purpose_card{
      width: 100%;
      margin-top: 1rem;
    }

    .about_rules{
      margin-top: 3rem;
      padding:1rem 1rem;
      background: #b1defc;
    }
    .about_rules span b{
      display: block;
      margin-top: 1rem;
    }
    .about_rules .download_btn{
      font-size: 1rem;
      margin-top: 1rem;
    }

    .about_contact{
      display: block;
    }
  }

`;

export default AboutStyle;
