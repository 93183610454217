import {styled} from 'styled-components'


const FormStyle = styled.div`
    .something{
        height: 100vh;
        margin-top: 100px
        ;
    }
    @media(max-width: 740px){
        .something{
            margin-top: 1rem;

        }
    }

    .forms{
        width: 100%;
        display:flex;
        justify-content: center;
        align-items: center;
        // background: red;
    }


`;

export default FormStyle