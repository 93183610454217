import styled from "styled-components";


const AdminLoginFormStyle = styled.div`
  width: 500px !important;
  background: white;
  box-shadow: 2px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;


.admin_form__sure {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
}

.admin_form__sure img, .admin__form_logo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}

.admin_form__sure p {
  text-align: center;
}

.admin_form__sure p span {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 500;
  line-height: 2;
}

.admin_form__sure .btns {
  width: 100%;
  height: 2rem;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: var(--primary-blue-color);
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 1rem;
  gap: 0.2rem;
  transition: all 0.5s;
}

.admin_form__sure .btns button {
  cursor: pointer;
  border: none;
  padding: 0.2rem 1rem;
  border-radius: 0.2rem;
  transition: all 0.5s;
}

.admin_form__sure .btns button:hover {
  width: calc(70px + 20px);
  background: red; 
  color: white; 
}
.admin_form__sure .btns button:nth-of-type(2):hover {
  background: green; 
}
.login__form{
  width: 100%;
  padding: 7rem 2rem 2rem 2rem ;
}

.login_form__header{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 1rem;
  padding: 0.5rem;
  align-items: center;
  background: var(--primary-blue-color);
  position: absolute;
  top: 0;
  left: 0;
}
.login_form__header img{
  width: 100px;
  height: 100px;
}

.login_form__header p{
  font-size: 1.2rem;
  color: white;
  font-weight: bold;
}

.login__form .form__control{
  margin: 1.5rem 0rem;
}

.login__form .form__control label{
  display: block;
  margin-bottom: 0.2rem;
}

.login__form .form__control input{
  width: 100% !important;
  padding: 0.5rem 0.5rem;
}

.login_form__btns{
 text-align: end;
}

.login_form__btns button{
border: none;
font-weight: 600;
padding: 0.5rem 1.5rem;
margin-left: 0.2rem;
box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.login_form__btns button:nth-last-of-type(1){
background-color: var(--primary-btn-color);
color: #fff;
}
.login_form__btns button:nth-last-of-type(1):active{
  background: blue;
}
`
export default AdminLoginFormStyle