import React, { useState, useEffect } from "react";
import Logo from "../../assets/imgs/logo.jpg";
import AdminLoginFormStyle from "./AdminLoginFormStyle";
import Backdrop from "../backdrop/Backdrop";
import CustomToast from "../toast";
import { toast } from "react-toastify";
import { axiosInstance } from "../../utils/axiosInstance";

const AdminLoginForm = ({ setOpenLoginForm  }) => {
  const [step, setStep] = useState("admin_form__sure");
  const [submitting, setSubmitting] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });

  console.log(setOpenLoginForm )

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn === "true") {
      toast.warn("User is already logged in.");
    }
  }, []);

  const handleNext = (e) => {
    e.stopPropagation();
    setStep("login__form");
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    setSubmitting(true);
    try {
      const response = await axiosInstance.post("/login", loginData);
      if (response.status === 200) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("isLoggedIn", response.data.isLoggedIn);
        toast.success("Login successful!");
        window.location.reload();
      } else {
        throw new Error("Invalid credentials");
      }
    } catch (error) {
      toast.error("Login failed. Please check your credentials.");
      console.error("Login Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleEmailChange = (e) => {
    e.stopPropagation();
    setLoginData({ ...loginData, email: e.target.value });
  };

  const handlePasswordChange = (e) => {
    e.stopPropagation();
    setLoginData({ ...loginData, password: e.target.value });
  };

  return (
    <>
      {setOpenLoginForm  && (
        <Backdrop onClose={() => setOpenLoginForm (false)}>
          <AdminLoginFormStyle className={`admin__form`}>
            <CustomToast /> {/* Use the CustomToast component */}
            {step === "admin_form__sure" && (
              <div
                setOpenLoginForm ={setOpenLoginForm }
                onClick={(e) => e.stopPropagation()}
                className={`admin_form__sure`}
              >
                <img src={Logo} className="admin__form_logo" alt="logo" />
                <span>
                  <p>
                    <span>Are you Admin? !!</span> <br /> then you can log in
                    with email and password to explore the data of our
                    community!
                  </p>
                  <div className="btns">
                    <button onClick={() => setOpenLoginForm (false)}>Cancel</button>
                    <button onClick={handleNext}>Next</button>
                  </div>
                </span>
              </div>
            )}

            {step === "login__form" && (
              <form
                onClick={(e) => e.stopPropagation()}
                className="login__form"
                onSubmit={handleLogin}
              >
                <div className="login_form__header">
                  <img src={Logo} className="admin__form_logo" alt="logo" />
                  <p>
                    GTI ကျောင်းပေါင်းစုံလူမှုရေးအသင်း
                    <br /> မန္တလေးဒေသ
                  </p>
                </div>
                <div className="form__control">
                  <label htmlFor="email">E-Mail</label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    placeholder="Email"
                    value={loginData.email}
                    onChange={handleEmailChange}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <div className="form__control">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    name="password"
                    placeholder="Password"
                    id="password"
                    value={loginData.password}
                    onChange={handlePasswordChange}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <div className="login_form__btns">
                  <button
                    className="btn"
                    type="button"
                    onClick={() => setOpenLoginForm (false)}
                  >
                    Cancel
                  </button>
                  <button className="btn" type="submit" onClick={handleLogin}>
                    {submitting ? "Logging In..." : "Login"}
                  </button>
                </div>
              </form>
            )}
          </AdminLoginFormStyle>
        </Backdrop>
      )}
    </>
  );
};

export default AdminLoginForm;
