import React from "react";
import "./globals.css";
import "./assets/fonts/fonts.css";
import Navbar from "./components/Navbar/Navbar";
import Routers from "./routes";

const App = () => {
  return (
      <>
        {/* <Navbar /> */}
        <Routers />
      </>
  );
};

export default App;
