import { styled } from "styled-components";

const NewsStyle = styled.div`
  margin-bottom: 5rem;

  .head_news {
    text-align: center;
    color: #5161ce;
  }
  .no_news {
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ccc;
    padding: 50px;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    color: orange;
    font-size: 1.2rem;
    font-weight: bold;
  }
  .loading {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .news {
    width: 100%;
    max-width: 1200px;
    margin: 5.5rem auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .news-card {
    background: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out;

    &:hover {
      transform: scale(1.02);
    }
  }

  .rcd_img {
    width: 100%;

    display: flex;
    overflow-x: auto;
    gap: 8px;
    padding: 8px;
    border-bottom: 1px solid #ddd;

    img {
      width: 100%;
      height: 200px;
      object-fit: cover;
      border-radius: 4px;
    }
  }

  .news_info {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .first {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title_new {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .title_date {
      font-size: 1rem;
      color: #777;
    }

    .second {
      .title_description {
        font-size: 1.1rem;
        color: #333;
      }
    }
  }

  .read_more_btn {
    margin-top: auto;
    align-self: flex-end;
    font-weight: bold;
    color: blue;
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: darkblue;
    }
  }

  @media (max-width: 740px) {
    .news {
      margin: 0;
      padding: 1rem;
    }
    .news-card {
      flex-direction: column;
    }
  }
`;

export default NewsStyle;
