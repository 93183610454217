import { styled } from "styled-components";

const UserDetailStyle = styled.div`

  .loading{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items:center;
  }

  .back__container {
    width: 100%;
    background: #8fd2ff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
  }

  .back_icon {
    font-size: 1.8rem;
    padding: 0.4rem;
    display: flex;
    align-items: center;
  }

  .user__detail{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .user__detail_container {
    width: 90%;
    margin-top: 3rem;
    box-shadow: 1px 1px 1px gray;
    border-radius: 10px;
}

.user_detail_head {
    padding: 1rem;
    display: flex;
    background: #2b93d9;
    color: white;
    justify-content: space-between;
    border-top-left-radius: 20px;
    border-top-right-radius: 10px;
  }

  .user_detail_head .name_section, .year_section{
    padding: 1rem;
  }

  .name_section h1{
    font-size: 1.5rem;
  }

  .name_section span{
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  .year_section p.label{
    font-size: 1rem;
    font-weight: bold;
  }

  .year_section p.year{
    margin-top: 0.5rem;
    font-size: 1.2rem;
    text-align: center;
  }


  .user_detail_body{
    background: #c2e6ff;
    padding: 2rem;
  }

  .user_detail_body .major_region_section{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .major_region_section p span.label{
    font-size: 1rem;
    font-weight: bold;
  }

  .major_region_section p{
    font-size: 1rem;
  }

  .annotation_section{
    width: 100%;
    display: flex;
    margin-top: 1rem;
  }

  .annotation_section p.label{
    width: 10%;
    font-size: 1rem;
    font-weight: bold;  
  }

  .annotation_section p.note{
    width: 90%;
    font-size: 1rem;
    text-align: justify;
    line-height: 1.4;
    padding: 0 0.3rem;
  }


  .user_detail_body .buttons{
    margin-top: 3rem;
    display: flex;
    justify-content: end;
    gap: 0.5rem;
  }

  .buttons .edit, .delete{
    width: 100px;
    border: none;
    background: #b0252e;
    color: white;
    border-radius: 1rem;
    padding: 0.5rem 1rem;
  }

  .buttons .edit{
    background: #fff;
    box-shadow: 2px 2px 1px #ccc;
    color: black;
  }

  @media(max-width: 1170px){
    .annotation_section{
      display: block;
    }
    .annotation_section p.label{
      width: 100%;
    }
  
    .annotation_section p.note{
      width: 100%;
      margin-top: 0.5rem;
      padding: 0;
    }
  }
`;

export default UserDetailStyle;
