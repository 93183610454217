import React, { useEffect, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import NewsStyle from "./NewsStyle";
import { useNavigate } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";
import { axiosInstance } from "../../utils/axiosInstance";

const News = () => {
  const [news, setnews] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const getNews = async () => {
    try {
      const response = await axiosInstance.get("/admin/get-news");
      if (response.status === 200) {
        const responseData = response.data;
        setnews(responseData);
        console.log("Response news: ", responseData);
        setIsLoading(false);
      } else {
        console.error("Fetch news Status Failed:", response.statusText);
      }
    } catch (error) {
      console.log("Fetch news Status Error: ", error);
    }
  };

  useEffect(() => {
    getNews();

    const pollingInterval = setInterval(() => {
      getNews();
    }, 7 * 24 * 60 * 60 * 1000);

    return () => clearInterval(pollingInterval);
  }, []);

  const handleReadMore = async (newData) => {
    try {
      const response = await axiosInstance.get(`/news/${newData._id}`);
      if (response.status === 200) {
        const responseData = response.data;
        console.log("Response News Detail: ", responseData);
        navigate(`/news/${newData._id}`, { state: { newsId: newData._id } });
      } else {
        console.error("Fetch News Detail Status Failed:", response.statusText);
      }
    } catch (error) {
      console.log("Fetch News Status Error: ", error);
    }
  };

  return (
    <NewsStyle>
      <Navbar />
      <div className="news">
        <h1 className="head_news">မန္တလေးဒေသ အခြေစိုက် G.T.I ကျောင်းပေါင်းစုံ လူမှုရေးအသင်း သတင်းများ</h1>
        {isLoading && (
          <div className="loading">
            <TailSpin color="skyblue" radius={8} />
          </div>
        )}
        {!isLoading && news.length === 0 && <div className="no_news">No News Yet!</div>}
        {news.map((newData, index) => (
          <div key={index} className="news-card">
            <div className="rcd_img">
              {newData.recordImg.map((img, imgIndex) => (
                <img key={imgIndex} src={img} alt={`News Image ${imgIndex}`} />
              ))}
            </div>
            <div className="news_info">
              <div className="first">
                <p className="title_new">{newData.ceremony}</p>
                <p className="title_date">
                  {new Date(newData.date).toLocaleDateString()}
                </p>
              </div>
              <div className="second">
                <p className="title_description">
                  {newData.description.slice(0, 95)}
                  {newData.description.length > 95 ? "..." : ""}
                </p>
              </div>
            </div>
            <button
              className="read_more_btn"
              onClick={() => handleReadMore(newData)}
            >
              Read More -{">"}
            </button>
          </div>
        ))}
      </div>
    </NewsStyle>
  );
};

export default News;
