import { styled, keyframes } from 'styled-components';

const floatAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const HomeStyle = styled.div`
  position: relative;
  z-index: 0;

  .table--container {
    position: relative;
  }

  .loading svg {
    width: 40px;
    height: 40px;
    margin: 20px auto;
  }

  .head {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    text-align: center;
  }

  .head .logo {
    border-radius: 50%;
  }

  .head span {
    margin-top: 10px;
    font-size: 1.5rem;
  }

  .search_box {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  input.search_input {
    width: 50%;
    padding: 13px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 40px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease-in-out;
  }

  input.search_input:focus {
    outline: none;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  }

  .table--container {
    overflow-x: auto;
  }

  .table {
    width: 100%;
    overflow-y: hidden;
    border-collapse: collapse;
    margin-top: 20px;
    font-size: 0.9rem;
  }

  .table tbody {
    max-height: 300px !important;
    overflow-y: auto;
    position: relative;
  }
  

  th,
  td {
    padding: 15px;
    text-align: center;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #3498db;
    color: #fff;
  }

  tr:hover {
    background-color: #f5f5f5;
  }

  .edit-delete-buttons {
    width: 100%;
    background: ;
  }

  .login_button,
  .logout_button {
    position: fixed;
    bottom: 50px;
    left: 30px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-size: 20px;
    cursor: pointer;
    padding: 0;
    animation: ${floatAnimation} 2s infinite ease-in-out;
    transition: all 0.3s ease;

    &:hover {
      background-color: #2980b9;
    }

    &:hover::before {
      content: 'LogIn';
      position: absolute;
      top: -30px;
      left: -5px;
      background: #999;
      font-size: 1rem;
      padding: 0.3rem 0.5rem;
      border-radius: 0.3rem;
    }
  }

  .logout_button:hover::before {
    content: 'Logout';
    left: -10px;
  }

  .pagination{
    display: flex;
    justify-content: center;
    z-index: 0;
    margin: 50px auto;
  }

  @media (max-width: 740px) {
    .head {
      padding: 10px;
    }

    .logo {
      margin-top: 1rem;
    }

    .login_button,
    .logout_button {
      bottom: 50px;
      left: 10px;
    }

    input.search_input {
      width: 90%;
    }
  }
`;

export default HomeStyle;
