import React from 'react'
import { useNavigate } from 'react-router-dom'
import NotFoundStyle from './NotFoundStyle'

const NotFound = () => {
    const navigate = useNavigate()
  return (
    <NotFoundStyle>
        <h2>404 Not Found</h2>
        <p>Go Back -<button className='goBack' onClick={() => navigate('/')}>Home</button></p>
    </NotFoundStyle>
  )
}

export default NotFound